import { push } from 'connected-react-router';
import { actionChannel, call, put, take, takeLatest } from 'redux-saga/effects';
import {
    AGREEMENTS,
    BLOOD_VALUES,
    MGDL,
    REACTIVATION_MODAL,
    SHOW_SPLITS,
    UPDATE_PASSWORD_MODAL,
    USER_ID,
    USER_NAME,
} from '@constants';
import * as actions from '@services/auth/actions';
import { loadBrandingFailure, loadBrandingRequest, loadBrandingSuccess } from '@services/branding/actions';
import { loadConfigRequest } from '@services/config/actions';
import { changeLocaleRequest } from '@services/language/actions';
import { closeModalRequest, openModalRequest } from '@services/modal/actions';
import { addErrorNotification, addSuccessNotification, clearNotifications } from '@services/notifications/actions';
import {
    loadUserRequest,
    updateUserTermsFailure,
    updateUserTermsRequest,
    updateUserTermsSuccess,
} from '@services/user/actions';
import { isMFAPending, isMFARequired } from '@utils/mfa';
import { clearStorage, getItem, removeItem, setItem } from '@utils/storageUtils';
import {
    checkCode,
    checkSSO,
    clientLogin,
    get2FACodeStatus,
    getResetPassUrl,
    login,
    loginSSO,
    logout,
    resend2FACode,
    submit2FACode,
    update2FAPhoneNumber,
    updatePassword,
} from './api';

const HOME_PATH = '/';

export function* loginSaga(action) {
    try {
        const { data } = yield call(login, action.payload);
        const user = data;
        const is2FARequired = isMFAPending(user) || isMFARequired(user);
        yield put(clearNotifications());
        if (!is2FARequired) {
            yield put(loadUserRequest(user.id));
            yield put(loadBrandingRequest({ first: true }));
            const channel = yield actionChannel([loadBrandingSuccess, loadBrandingFailure]);
            yield take(channel);
        }
        const bloodValues = getItem(BLOOD_VALUES) || MGDL;
        yield setItem([USER_ID, USER_NAME, BLOOD_VALUES, SHOW_SPLITS], [data.id, data.name, bloodValues, false]);
        yield put(actions.loginSuccess(data));
    } catch (e) {
        yield put(actions.loginFailure(e));
        yield put(addErrorNotification(e));
    }
}

export function* loginSSOSaga() {
    try {
        const { data } = yield call(loginSSO);
        window.location.href = data.url;
    } catch (e) {
        yield put(addErrorNotification(e));
    }
}

export function* checkSSOSaga(action) {
    try {
        const { data } = yield call(checkSSO, action.payload);
        yield put(loadUserRequest(data.id));
        yield put(loadBrandingRequest({ first: true }));
        yield put(clearNotifications());
        const channel = yield actionChannel([loadBrandingSuccess, loadBrandingFailure]);
        yield take(channel);
        yield setItem([USER_ID, USER_NAME, BLOOD_VALUES, SHOW_SPLITS], [data.id, data.name, MGDL, false]);
        yield put(actions.loginSuccess(data));
    } catch (e) {
        yield put(addErrorNotification(e));
        yield put(push(HOME_PATH));
    }
}

export function* logoutSaga({ payload, meta }) {
    try {
        yield clearStorage();
        yield call(logout);
        yield put(actions.logOutSuccess());
        yield put(push(HOME_PATH));
        if (payload.showReactivationModal) {
            yield put(openModalRequest({ name: REACTIVATION_MODAL, meta }));
        }
        yield put(loadConfigRequest());
    } catch (e) {
        yield put(actions.logOutSuccess());
        yield put(push(HOME_PATH));
        yield put(loadConfigRequest());
    }
}

export function* accessCodeSaga(action) {
    try {
        const { data } = yield call(checkCode, action.payload?.accessCode);
        yield put(actions.checkCodeSuccess(data));
    } catch (e) {
        yield put(actions.checkCodeFailure(e));
        yield put(addErrorNotification(e));
    }
}

export function* resetPasswordSaga(action) {
    try {
        const { data } = yield call(getResetPassUrl, action.payload);
        yield put(actions.resetPasswordSuccess(data));
    } catch (e) {
        yield put(actions.resetPasswordFailure(e));
        yield put(addErrorNotification(e));
    }
}

export function* updatePasswordSaga(action) {
    try {
        const { data } = yield call(updatePassword, action.payload);
        yield put(actions.updatePasswordSuccess(data));
        yield put(closeModalRequest({ name: UPDATE_PASSWORD_MODAL }));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.updatePasswordFailure(e));
        yield put(addErrorNotification(e));
    }
}

export function* couponCodeSaga(action) {
    try {
        const { data } = yield call(checkCode, action.payload.couponCode);
        yield put(actions.checkCouponCodeSuccess(data));
    } catch (e) {
        yield put(actions.checkCouponCodeFailure(e));
        yield put(addErrorNotification(e));
    }
}

export function* update2FAPhoneNumberSaga(action) {
    try {
        const { countryCallingCode, phoneNumber } = action.payload;
        const { data } = yield call(update2FAPhoneNumber, countryCallingCode, phoneNumber);
        yield put(actions.update2FAPhoneNumberSuccess(data));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.update2FAPhoneNumberFailure());
        yield put(addErrorNotification(e));
    }
}

export function* resend2FACodeSaga() {
    try {
        const { data } = yield call(resend2FACode);
        yield put(actions.disable2FACodeResend());
        yield put(actions.resend2FACodeSuccess(data));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.resend2FACodeFailure());
        yield put(actions.load2FACodeStatusRequest());
        yield put(addErrorNotification(e));
    }
}

export function* submit2FACodeSaga(action) {
    const userId = getItem([USER_ID]);
    const agreements = getItem([AGREEMENTS]) || [];
    try {
        const { otp } = action.payload;
        yield call(submit2FACode, otp);
        if (agreements.length) {
            yield put({ ...updateUserTermsRequest({ agreements }), meta: { hideNotification: true } });
            removeItem(AGREEMENTS);
            const channel = yield actionChannel([updateUserTermsSuccess, updateUserTermsFailure]);
            yield take(channel);
        }
        yield put(actions.submit2FACodeSuccess());
        yield put(loadUserRequest(userId));
        yield put(loadBrandingRequest({ first: true }));
        const channel = yield actionChannel([loadBrandingSuccess, loadBrandingFailure]);
        yield take(channel);
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.submit2FACodeFailure());
        yield put(addErrorNotification(e));
    }
}

export function* load2FACodeStatusSaga() {
    try {
        const { data } = yield call(get2FACodeStatus);
        yield put(actions.load2FACodeStatusSuccess(data));
    } catch (e) {
        yield put(actions.load2FACodeStatusFailure(e));
    }
}

export function* loadClientLoginSaga(action) {
    try {
        const { sourcePlatform, accessToken, refreshToken } = action.payload;
        const { data } = yield call(clientLogin, sourcePlatform, accessToken, refreshToken);
        yield put(actions.loadClientLoginSuccess(data));
    } catch (e) {
        yield put(actions.loadClientLoginFailure(e));
    }
}

export function* loginSuccessSaga(action) {
    const { language } = action?.payload || {};
    yield put(changeLocaleRequest({ locale: language }));
}

export default function* Saga() {
    yield takeLatest(actions.loginRequest, loginSaga);
    yield takeLatest(actions.loginSSORequest, loginSSOSaga);
    yield takeLatest(actions.checkSSORequest, checkSSOSaga);
    yield takeLatest(actions.logOut, logoutSaga);
    yield takeLatest(actions.checkCodeRequest, accessCodeSaga);
    yield takeLatest(actions.resetPasswordRequest, resetPasswordSaga);
    yield takeLatest(actions.updatePasswordRequest, updatePasswordSaga);
    yield takeLatest(actions.checkCouponCodeRequest, couponCodeSaga);
    yield takeLatest(actions.update2FAPhoneNumberRequest, update2FAPhoneNumberSaga);
    yield takeLatest(actions.resend2FACodeRequest, resend2FACodeSaga);
    yield takeLatest(actions.submit2FACodeRequest, submit2FACodeSaga);
    yield takeLatest(actions.load2FACodeStatusRequest, load2FACodeStatusSaga);
    yield takeLatest(actions.loadClientLoginRequest, loadClientLoginSaga);
    yield takeLatest(actions.loginSuccess, loginSuccessSaga);
    yield takeLatest(actions.loadClientLoginSuccess, loginSuccessSaga);
}
