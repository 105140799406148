import { call, put, takeLatest, takeEvery, all } from 'redux-saga/effects';
import { addErrorNotification } from '@services/notifications/actions';
import { getMediaLink } from '@utils/media';
import * as actions from './actions';
import { getMedia, getMediaBinary, saveAnswers, startSurvey } from './api';

export function* createStartSurveyWorker({ meta, payload }) {
    try {
        const { data } = yield call(startSurvey, payload);

        const assetKeys = data.questions
            .map(question => question.assets?.find(({ key }) => key === 'image')?.value)
            .filter(Boolean);

        if (assetKeys.length) {
            yield put(actions.loadQuestionMediaRequest(assetKeys));
        }
        yield put(actions.createStartSurveySuccess({ ...data, meta }));
    } catch (e) {
        yield put(actions.createStartSurveyFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* saveAnswersWorker({ meta, payload }) {
    try {
        const { data } = yield call(saveAnswers, payload);
        yield put(actions.saveAnswersSuccess({ ...data, meta }));
    } catch (e) {
        yield put(actions.saveAnswersFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* silentsaveAnswersWorker({ meta, payload }) {
    try {
        const { data } = yield call(saveAnswers, payload);
        yield put(actions.silentSaveAnswersSuccess({ ...data, meta }));
    } catch (e) {
        yield put(actions.silentSaveAnswersFailure(e.message));
    }
}

export function* downloadImageWorker({ payload }) {
    try {
        if (!payload.id) {
            yield put(actions.downloadMediaFailure());
            return;
        }

        const { data, headers = {} } = yield call(getMediaBinary, getMediaLink(payload.id, 'image'));
        if (!data.byteLength) {
            throw new Error('Image not found');
        }
        const blob = new Blob([data], { type: headers['content-type'] });
        const imageUrl = URL.createObjectURL(blob);

        yield put(
            actions.downloadMediaSuccess({
                data: imageUrl,
                key: payload.key,
            }),
        );
    } catch (e) {
        yield put(actions.downloadMediaFailure(e.message));
    }
}

export function* loadQuestionMediaWorker({ payload = [] }) {
    try {
        const { data } = yield call(getMedia, payload.join(','));
        const medias = data.items.map(item => ({ ...item.media?.at(0), key: item.key }));

        yield all(medias.map(media => put(actions.downloadMediaRequest(media))));

        yield put(actions.loadQuestionMediaSuccess());
    } catch (e) {
        yield put(actions.loadQuestionMediaFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.createStartSurveyRequest, createStartSurveyWorker);
    yield takeLatest(actions.saveAnswersRequest, saveAnswersWorker);
    yield takeLatest(actions.silentSaveAnswersRequest, silentsaveAnswersWorker);
    yield takeLatest(actions.loadQuestionMediaRequest, loadQuestionMediaWorker);
    yield takeEvery(actions.downloadMediaRequest, downloadImageWorker);
}
