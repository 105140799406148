import routes from '@constants/apiRoutes';
import { getItems, newItem } from 'common/api/basic';
import { getBinary } from 'common/api/request';

export function startSurvey(key) {
    return newItem(routes.startSurvey, {}, { key });
}

export function saveAnswers(data) {
    return newItem(routes.saveAnswers, data);
}

export function getMedia(keys) {
    return getItems(routes.assets, { keys });
}

export function getMediaBinary(url) {
    return getBinary(url, { ignoreError: true });
}
