import { groupBy, identity, prop, reduce } from 'ramda';
import { useIntl } from 'react-intl';

const KEY_LENGTH = 6;
export const ENTER_KEY_CODE = 13;

export const generateRandomString = length =>
    Math.random()
        .toString(36)
        .substr(2, length);

export const groupByKind = groupBy(prop('kind'));

export const getIdsMap = reduce((acc, id) => ({ ...acc, [id]: id }), {});

export const generateLoadObjects = length =>
    Array(length)
        .fill(identity)
        .map(() => ({ id: generateRandomString(KEY_LENGTH), empty: true }));

export const swap = (array, firstIndex, secondIndex) =>
    array.map((item, index) => {
        if (index === firstIndex) return array[secondIndex];
        if (index === secondIndex) return array[firstIndex];
        return item;
    });

export const move = (array, firstIndex, secondIndex) => {
    const options = array.slice();
    const option = array[firstIndex];
    options.splice(firstIndex, 1);
    options.splice(secondIndex, 0, option);
    return options;
};

export const handleKeyPress = (e, handleClick, value = null) => e.charCode === ENTER_KEY_CODE && handleClick(value);

export const generatePageTitle = title => {
    const intl = useIntl();
    document.title = intl.formatMessage({ id: 'WEB_PAGE_TITLE' }, { title });
    return document.title;
};

export const getLogoId = separateLogo => (separateLogo ? 'ic_logo_footer' : 'ic_logo_dacadoo');

export const sortBy = fieldName => (a, b) => {
    if (a[fieldName] < b[fieldName]) {
        return -1;
    }
    if (a[fieldName] > b[fieldName]) {
        return 1;
    }
    return 0;
};

export const resolvePath = (obj, path, separator = '.') =>
    path.split(separator).reduce((o, key) => (o ? o[key] : ''), obj);

export const createMapByKey = (arr = [], key = '') =>
    arr.reduce((acc, item) => {
        const value = resolvePath(item, key);
        acc[value] = item;
        return acc;
    }, {});

export const arraysEqual = (arr1 = [], arr2 = []) => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    return sortedArr1.every((item, index) => item === sortedArr2[index]);
};

export const haveCommonItems = (arr1 = [], arr2 = []) => {
    const set = new Set(arr1);
    return arr2.some(item => set.has(item));
};
