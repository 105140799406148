import { handleActions } from 'redux-actions';
import * as types from './actionTypes';

export const initialState = {
    details: null,
    medias: [],
    downloadInProgress: 0,
};

export default handleActions(
    {
        [types.CREATE_START_SURVEY_SUCCESS]: (state, action) => ({
            ...state,
            details: action.payload,
        }),
        [types.CREATE_SAVE_ANSWER_SURVEY_SUCCESS]: (state, action) => ({
            ...state,
            details: action.payload,
        }),
        [types.SILENT_SAVE_ANSWER_SURVEY_SUCCESS]: (state, action) => ({
            ...state,
            details: action.payload,
        }),
        [types.INIT_SURVEY_QUESTION_MEDIA]: state => ({
            ...state,
            medias: [],
        }),
        [types.DOWNLOAD_MEDIA_REQUEST]: state => ({
            ...state,
            downloadInProgress: state.downloadInProgress + 1,
        }),
        [types.DOWNLOAD_MEDIA_SUCCESS]: (state, action) => ({
            ...state,
            medias: [...state.medias, action.payload],
            downloadInProgress: state.downloadInProgress - 1,
        }),
        [types.DOWNLOAD_MEDIA_FAILURE]: state => ({
            ...state,
            downloadInProgress: state.downloadInProgress - 1,
        }),
        [types.RESET_SURVEY]: () => initialState,
    },
    initialState,
);
