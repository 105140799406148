import * as types from '@services/surveys/actionTypes';
import { createAction } from '@utils/redux';

export const createStartSurveyRequest = createAction(types.CREATE_START_SURVEY_REQUEST);
export const createStartSurveySuccess = createAction(types.CREATE_START_SURVEY_SUCCESS);
export const createStartSurveyFailure = createAction(types.CREATE_START_SURVEY_FAILURE);
export const resetSurvey = createAction(types.RESET_SURVEY);

export const saveAnswersRequest = createAction(types.CREATE_SAVE_ANSWER_SURVEY_REQUEST);
export const saveAnswersSuccess = createAction(types.CREATE_SAVE_ANSWER_SURVEY_SUCCESS);
export const saveAnswersFailure = createAction(types.CREATE_SAVE_ANSWER_SURVEY_FAILURE);

export const silentSaveAnswersRequest = createAction(types.SILENT_SAVE_ANSWER_SURVEY_REQUEST);
export const silentSaveAnswersSuccess = createAction(types.SILENT_SAVE_ANSWER_SURVEY_SUCCESS);
export const silentSaveAnswersFailure = createAction(types.SILENT_SAVE_ANSWER_SURVEY_FAILURE);

export const loadQuestionMediaRequest = createAction(types.LOAD_SURVEY_QUESTION_MEDIA_REQUEST);
export const loadQuestionMediaSuccess = createAction(types.LOAD_SURVEY_QUESTION_MEDIA_SUCCESS);
export const loadQuestionMediaFailure = createAction(types.LOAD_SURVEY_QUESTION_MEDIA_FAILURE);

export const downloadMediaRequest = createAction(types.DOWNLOAD_MEDIA_REQUEST);
export const downloadMediaSuccess = createAction(types.DOWNLOAD_MEDIA_SUCCESS);
export const downloadMediaFailure = createAction(types.DOWNLOAD_MEDIA_FAILURE);

export const initQuestionMedia = createAction(types.INIT_SURVEY_QUESTION_MEDIA);
